<template>
  <div class="error">
    <div>
      <div class="txt">404</div>
      <div class="txt">页面飞走了</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.error {
  width: 100%;
  height: 100vh;
  background: radial-gradient(ellipse at center,#23508a 0,#1b3b62 50%,#1a365b 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #ffffff;
  font-weight: bold;
}
.error .txt {
  text-align: center;
  text-shadow: 5px -8px 3px rgba(0, 0, 0, .5);
  letter-spacing: 4px;
}
</style>